import React, { createContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"

const defaultState = {
  stripeCustomer: {},
  setStripeCustomer: () => {},
  militaryRx: false,
  lifemartRx: false,
  setMilitaryRx: () => {},
  shippingPrice: "",
  setShippingPrice: () => {},
}

const CustomerContext = createContext(defaultState)

export const CustomerContextProvider = ({ children }) => {
  const [stripeCustomer, setStripeCustomer] = useState(defaultState.stripeCustomer)
  const [militaryRx, setMilitaryRx] = useState(defaultState.militaryRx)
  const [lifemartRx, setLifemartRx] = useState(defaultState.lifemartRx)
  const [shippingPrice, setShippingPrice] = useState(defaultState.shippingPrice)
  const [cookies] = useCookies(["user-jwt"])
  const userToken = cookies["user-jwt"]

  useEffect(() => {
    const customerData = JSON.parse(localStorage.getItem("customer_data"))
    setShippingPrice(new Date(customerData?.created_at) < new Date("2023-10-30") ? 3.00 : 3.99)
  }, [stripeCustomer, userToken])

  return (
    <CustomerContext.Provider
      value={ {stripeCustomer, setStripeCustomer, militaryRx, setMilitaryRx, lifemartRx, setLifemartRx, shippingPrice, setShippingPrice} }
    >
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerContext
