import React, {createContext, useEffect, useState} from "react"

const defaultTests = {
  oneEyeTest: {
    variant: false
  },
  otpTest: {
    variant: false,
  },
}

const defaultState = {
  setVariant: (testName, variant) => {},
  getVariant: (testName) => {},
}

const AbTestsContext = createContext(defaultState)

export const AbTestsContextProvider = ({children}) => {
  const [tests, setTests] = useState(defaultTests)

  const setVariant = (testName, variant) => {
    setTests({...tests, [testName]: {...tests[testName], variant}})
  }

  const getVariant = (testName) => {
    return tests[testName].variant
  }

  const defaultCheck = async (testName) => {
    for (let i = 0; i < 50; i++) {
      if (typeof window !== "undefined" && window?.abtesting) {
        if (window?.abtesting?.tests?.[testName]) {
          return window?.abtesting?.tests?.[testName]
        }
      }
      await new Promise(resolve => setTimeout(resolve, 100))
    }
    return tests[testName].variant
  }

  useEffect(() => {
    for (const [testName, test] of Object.entries(tests)) {
      (async () => {
        const newVariant = await defaultCheck(testName)
        const newTest = {
          ...test,
          variant: newVariant
        }

        console.log(`Test "${testName}" was initialized with variant "${newVariant}"`)

        setTests((tests) => ({...tests, [testName]: newTest}))
      })()
    }
  }, []);

  return (
    <AbTestsContext.Provider
      value={{getVariant, setVariant}}
    >
      {children}
    </AbTestsContext.Provider>
  )
}

export default AbTestsContext
